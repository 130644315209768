<template>
  <div>
    <v-dialog persistent v-model="openModal" max-width="1000" height="1000">
      <v-card class="pa-5">
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!mostrarContenido">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-show="mostrarContenido">
            <v-col cols="12">
              <v-select
                v-model="direccion.idtipo_direccion"
                item-text="nombre"
                item-value="idtipo_direccion"
                :items="cmbTipoDireccion"
                label="Tipo de dirección"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                dense
                clearable
                clear-icon="mdi-close-circle"
                label="Dirección"
                v-model="direccion.direccion"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                dense
                clearable
                clear-icon="mdi-close-circle"
                label="Referencia"
                v-model="direccion.referencia"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <v-autocomplete
                @change="getProvincia(true)"
                dense
                prepend-icon="mdi-database-search"
                item-text="nombre"
                item-value="iddepartamento"
                :items="cmbDepartamento"
                label="Departamento"
                v-model="direccion.departamento"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <v-autocomplete
                @change="getDistrito"
                :loading="loaderProvincia"
                dense
                prepend-icon="mdi-database-search"
                item-text="nombre"
                item-value="idprovincia"
                :items="cmbProvincia"
                label="Provincia"
                v-model="direccion.provincia"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <v-autocomplete
                :loading="loaderDistrito"
                dense
                prepend-icon="mdi-database-search"
                item-text="nombre"
                item-value="iddistrito"
                :items="cmbDistrito"
                label="Distrito"
                v-model="direccion.distrito"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="text-center mt-2">
              <v-btn
                :loading="processForm"
                class="mr-2"
                color="error"
                outlined=""
                @click="openModal = false"
              >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
              <v-btn :loading="processForm" color="success" @click="modificarDireccion">
                <v-icon left="">mdi-check-bold</v-icon> Confirmar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      idreserva_medida_vista: "",
      processForm: false,
      mostrarContenido: false,
      loaderDepartamento: false,
      loaderProvincia: false,
      loaderDistrito: false,
      direccion: {
        idtipo_direccion: "",
        direccion: "",
        referencia: "",
        departamento: "",
        provincia: "",
        distrito: "",
      },
      cmbTipoDireccion: [],
      cmbDepartamento: [],
      cmbProvincia: [],
      cmbDistrito: [],
    };
  },
  props: {
    objModalDireccion: {
      type: Object,
    },
  },
  watch: {
    objModalDireccion(val) {
      this.mostrarContenido = false;
      this.openModal = val.openModal;
      this.idreserva_medida_vista = val.idreserva_medida_vista;
      this.cmbTipoDireccion = this.$parent.cmbTipoDireccion;
      this.getDireccion();
    },
  },

  methods: {
    getDireccion() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/reservas/getDireccion",
        data: {
          idreserva_medida_vista: this.idreserva_medida_vista,
        },
      })
        .then((response) => {
          const reserva = response.data.reserva;
          this.direccion.idtipo_direccion = reserva.direccion_idtipo_direccion;
          this.direccion.direccion = reserva.direccion_direccion;
          this.direccion.referencia = reserva.direccion_referencia;
          this.direccion.departamento = reserva.direccion_iddepartamento;
          this.direccion.provincia = reserva.direccion_idprovincia;
          this.direccion.distrito = reserva.direccion_iddistrito;

          this.getDepartamento();
          this.getProvincia();
          this.getDistrito();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mostrarContenido = true;
        });
    },

    getDepartamento() {
      this.loaderDepartamento = true;
      this.axios({
        method: "POST",
        url: "api/panel/ubigeo/departamentos",
      })
        .then((response) => {
          this.cmbDepartamento = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loaderDepartamento = false;
        });
    },

    getProvincia(crear = null) {
      this.loaderProvincia = true;
      this.axios({
        method: "POST",
        url: "api/panel/ubigeo/provincias",
        data: {
          iddepartamento: this.direccion.departamento,
        },
      })
        .then((response) => {
          this.cmbProvincia = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loaderProvincia = false;
          if (crear) {
            this.cmbDistrito = [];
            this.direccion.provincia = "";
            this.direccion.distrito = "";
          }
        });
    },

    getDistrito() {
      this.loaderDistrito = true;
      this.axios({
        method: "POST",
        url: "api/panel/ubigeo/distritos",
        data: {
          iddepartamento: this.direccion.departamento,
          idprovincia: this.direccion.provincia,
        },
      })
        .then((response) => {
          this.cmbDistrito = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loaderDistrito = false;
        });
    },

    modificarDireccion() {
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/reservas/modificarDireccion",
        data: {
          idreserva_medida_vista: this.idreserva_medida_vista,
          direccion: this.direccion,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.openModal = false;

            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });

            this.$parent.listarRegistros();
          }
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al modificar este registro", {
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
};
</script>
