<template>
  <div>
    <v-dialog v-model="openModal" max-width="1000" height="1000">
      <v-card height="100vh">
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!loadIframe">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-show="loadIframe">
            <v-col cols="12">
              <iframe
                @load="loadIframe = true"
                :src="
                  apiUrl(
                    'final-reserva-examen-de-medida-de-vista/pdf?key=' +
                      idreserva_medida_vista
                  )
                "
                frameborder="0"
                style="min-width: 100%; width: 100%; height: 535px"
              ></iframe>
              <v-btn
                class="mr-2 text-center"
                color="error"
                outlined=""
                @click="openModal = false"
              >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      idreserva_medida_vista: "",
      loadIframe: false,
    };
  },
  props: {
    objModalImpresion: {
      type: Object,
    },
  },
  watch: {
    objModalImpresion(val) {
      this.loadIframe = false;
      this.openModal = val.openModal;
      this.idreserva_medida_vista = val.idreserva_medida_vista;
    },
  },
};
</script>
